import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import YouTube from 'react-youtube';
import { Seo } from '../../components/seo';

const EnrollInShipItSchool = ({ location }) => {
  const pageTitle = 'Enroll in Ship It School';
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="Ship It School is a video series that shows you the hardest part of a developerʼs job: shipping."
        pathname={location.pathname}
      />
      <Article>
        <h1 className="fancy">
          Ship It School Is a Video Series about Shipping
        </h1>
        <p>
          It's easy to write code, but it's hard to finish projects. I created
          Ship It School to show what it looks like to finish a project and put
          it out into the world. Watch the first season below.
        </p>
        <p>
          <YouTube videoId="ZT04INboZ3s"></YouTube>
          <YouTube videoId="hlY0ucnLcY4"></YouTube>
          <YouTube videoId="T2CMatAM-iU"></YouTube>
          <YouTube videoId="4Tr707zef8E"></YouTube>
        </p>
      </Article>
    </Layout>
  );
};
export default EnrollInShipItSchool;
